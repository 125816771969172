main.l00k.--bi.--slider {
    container-type: inline-size;

    article {
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr;
        display: grid;
    }



    @media only screen and (max-width: 969px) {
        &.w__l00k__nav {

            article {
                grid-template-rows: 1fr 1fr;
                grid-template-columns: 1fr;
            }
        }
    }

    @media only screen and (orientation: portrait) {

        article {
            grid-template-rows: 1fr 1fr min-content;
            grid-template-columns: 1fr;

            >section {
                &:nth-of-type(3) {
                    grid-area: unset;
                }
            }
        }

    }

    &.__arp {
        @media only screen and (max-width: 969px) {
            &.w__l00k__nav {

                article {
                    grid-template-rows: 1fr 1fr min-content;
                    grid-template-columns: 1fr;

                    >section {
                        &:nth-of-type(3) {
                            grid-area: unset;
                        }
                    }
                }
            }
        }

        & .hm__control.--rangeSlider>div h2.--longer {
            white-space: nowrap;
            font-size: clamp(2em, 7vmin, 5em);
        }
    }
}


#loop {

    & .bar {
        height: 100%;
        width: 100%;
        position: absolute;
        overflow: visible;
        text-align: center;
        display: grid;
        align-items: center;
        justify-items: center;
        grid-template-rows: 1fr min-content;

        .dot {
            display: block;
            height: 1em;
            width: 1em;
            border-radius: 111em;
            background-color: var(--color-foreground);
            z-index: 111;
            animation-delay: 2s;
            animation-name: fadeIn;
            transition-timing-function: easeInOut;
            animation-duration: calc(var(--animation-step) * 3);
            animation-fill-mode: both;
        }

        &.--left {}

        &.--right {}

        & #left,
        & #right {
            z-index: 11;
            position: absolute;
            top: 37.5%;
            left: 47.5%;
            height: 25%;
            width: 5%;
            border-radius: 0.25em;
            background-color: color-mix(in srgb, var(--color-foreground) 100%, var(--color-foreground) 0%);
        }

        & #left {
            // background-color: var(--color-background);
            display: block;
        }

        & #right {
            // background-color: var(--color-background);
            display: block;
        }
    }

    &.__fan {
        & .bar {

            @media only screen and (max-width: 799px), (orientation: portrait) {
                
                & .__viz {  
                    display: grid;
                    grid-template-columns: auto auto auto;
                    align-items: center;
                }

            }

            & #left,
            & #right {
                z-index: 11;
                position: relative;
                top: unset;
                left: unset;
                height: auto;
                width: clamp(10em, 40vmin, 40em);
                padding: var(--space);
                // border-radius: 111em;
                background-color: transparent;


                @media only screen and (max-width: 600px) {
                    width: clamp(8em, 80%, 20em);
                }

                @media only screen and (max-height: 1099px) {
                    width: clamp(8em, 28vmin, 20em);

                    

                }

                @media only screen and (max-width: 899px) and (orientation : landscape) {
                    width: clamp(10em, 20vmin, 20em) !important;
                }

                @media only screen and (min-width: 600px) {
                    // width: calc(38vmin - calc(var(--right) / 2));
                    margin-bottom: -2em

                }

                & span {
                    & svg {

                        path {
                            fill: var(--color-foreground);
                        }

                        #circleBG {
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }
}


main.l00k {
    .__player.__hand {
        z-index: 11;
        overflow: visible;

        #hand {
            position: absolute;
            width: clamp(8em, 20vmin, 10em);
            height: clamp(8em, 20vmin, 10em);
            z-index: 2222222 !important;
            top: unset;
            bottom: 30%;
            left: 0%;

            @media only screen and (max-width: 99px) {
                // top: unset;
                bottom: -5% !important;
                left: -5%;
            }

            // @media only screen and (max-width: 799px) {
            //     width: clamp(13em, 20vmin, 20em);
            //     height: clamp(13em, 20vmin, 20em);
            //     // bottom: 2em !important;
            //     // left: -110%;
            // }

            @media only screen and (max-width: 11000px) {
                // top: unset;
                // left: -110%;
                // bottom: 0em;
                // width: clamp(13em, 20vmin, 60em) !important;
                // height: clamp(12.5em, 20vmin, 60em) !important;
            }

            @media only screen and (max-width: 900px) and (orientation : landscape) {
                // top: unset;
                // left: -114%;
                // bottom: -0em;
                // width: clamp(11em, 20vmin, 60em) !important;
                // height: clamp(10.5em, 20vmin, 60em) !important;
            }
        }

    }
}



.hm__control {
    margin: 0 auto;
    // max-width: 20em;


    left: 0;
    width: 100%;
    max-width: clamp(3em, 80vmin, 30em);

    &.--pathSlider {
        max-width: none;

        svg {
            overflow: visible;

            g.pathSliderGroup {
                filter: url("#goo2");
            }

            g.oddLine,
            g.oddLineTwo {
                visibility: hidden;
            }

            g.evenLine {
                visibility: visible;
            }

            .pathSlider__handleCircle,
            .pathSlider__trackGoo,
            .pathSlider__track__oddLineGoo {
                fill: var(--color-foreground);
            }

            .pathSlider__track__oddLineGoo {
                visibility: hidden;
            }

            .pathSlider__trackGoo {
                fill: var(--color-foreground);
            }

            .pathSlider__track,
            .pathSlider__track__oddLine {
                fill: none;
                stroke: transparent;
                stroke-width: 0;
                stroke-linecap: round;
                stroke-miterlimit: 10;
            }


        }

        &.--oddLine {

            g.oddLine,
            g.oddLineTwo {
                visibility: visible;

                .pathSlider__track__oddLineGoo {
                    visibility: visible;
                }
            }

            g.evenLine {
                visibility: hidden;
            }
        }
    }

    &.--rangeSlider {

        >div {
            min-height: 3.5em;

            & h2 {
                // pointer-events: none;
                z-index: 111;
                position: absolute;
                width: 100%;
                top: calc(50% - 0.75em);
                display: grid;
                align-items: center;
                justify-content: center;
                font-size: clamp(2em, 9vmin, 4em);
                text-transform: uppercase;
                filter: url(#goo3);
                transform-origin: center;
                animation-delay: 2s;
                animation-name: fadeIn;
                transition-timing-function: easeInOut;
                animation-timing-function: easeInOut;
                animation-duration: calc(var(--animation-step) * 3);
                animation-fill-mode: both;

                transition:
                    transform calc(var(--animation-step) * 2) cubic-bezier(0, -0.5, 0.5, 1),
                    text-shadow calc(var(--animation-step) * 2) ease;

                abbr {
                    text-decoration: none;
                }

                &:hover {
                    cursor: pointer;
                    transform: scale(1.1);
                }

                &.--longer {
                    white-space: nowrap;
                    font-size: clamp(2em, 12vmin, 5em);
                }
            }

            &.slider__r,
            &.slider__l {
                & h2 {
                    top: calc(50% - 1em);
                }
            }
        }

        & svg.rangeSliderSvg {
            width: 100%;
            display: block;
            overflow: visible;

            & g.HandleGroup {

                & .HandleRect,
                & .HandleCircle,
                & .HandleRounded {

                    // visibility: hidden;
                    opacity: 0;
                }

                & .HandleRect {
                    // visibility: visible;
                    opacity: 1;

                }

                & .__Handle {

                    transform-origin: center center;
                    fill: var(--color-foreground);
                }
            }

            & g.TrackGroup {

                & rect,
                & path {
                    fill: var(--color-foreground);
                    // opacity: 0;
                }

                & .TrackRect {
                    visibility: hidden;
                }
            }

            & g.rangeSliderGroup {
                filter: url("#gooSlider");
            }

            & g.arrow-group {
                
                
                & line {
                    
                    stroke: var(--color-foreground);
                    stroke-width: 4px;
                    stroke-dasharray: 32, 4;
                    stroke-dashoffset: 0;
                    // stroke-linecap: round;
                }

                & path {
                    
                    fill: var(--color-foreground);

                }


            }
        }
    }

}




.arm__swinging__wind {
    filter: url(#armGoo);
}

svg#arm__svg {
    position: absolute;
    top: calc(50% - 0.5em);
    left: 50%;
    width: 30vmin;
    margin: 0 auto;
    transform: translate(-50%, -50%);


    .st1 {
        opacity: 0;
    }

    // .arm__tip {}

    .arm__dot,
    circle {
        fill: var(--color-foreground);
    }

    path {
        fill: var(--color-foreground--l80);
    }

    .arm__reach {
        stroke: var(--color-foreground--l80);
        stroke-width: 8;
        stroke-linecap: round;
        stroke-miterlimit: 10;
    }

    // g.arm-movement {}
}

.item__shell {
    border: 2px solid color-mix(in srgb, var(--color-foreground) 100%, white 50%);
    border-radius: 3em;

    &:before {
        border-radius: 3em;
        box-shadow: none !important;
    }
}

.__viz {
    width: 100%;
    height: 100%;
    display: grid;
    justify-items: center;
    align-content: center;
    // padding-top: calc(var(--space) * 1);
}

.hm__control {
    padding: 0 0 calc(var(--space) * 2) 0;

    &.--bottom {
        padding: 0;
    }
}

@container (max-width: 699px) {
    article {
        grid-template-rows: 1fr 1fr !important;
        grid-template-columns: 1fr !important;

    }

    .__viz {
        padding: 0 0 0 0;
    }

    #loop.__fan .bar #left,
    #loop.__fan .bar #right {
        width: clamp(10em, 80%, 30em);
    }

    .__viz {
        grid-template-columns: 1fr 2fr 1fr;
        align-items: center;
    }
}

@media only screen and (max-height: 699px) {
    .__viz {
        grid-template-columns: 1fr 2fr 1fr;
        align-items: center;
    }
}


body.__n {
    main .l00k__nav {
        // --color-background: var(--color-user-background);
        // --color-background: hsla(216, 15, 94, 1);
        background-color: var(--color-background);
        border-left: 1px solid var(--color-user-background);
        border-left: 1px solid color-mix(in srgb, var(--color-user-background) 70%, var(--color-foreground) 95%);

        @media only screen and (max-width: 599px) {
            border-left: 1px solid transparent;
        }

        & .l00k__author {
            background-color: var(--color-user-background);
            background-color: color-mix(in srgb, var(--color-user-background) 100%, var(--color-foreground) 15%);
        }

        h1 {

            filter: url(#goo2);
            color: var(--color-foreground);

        }

        ul {

            li {

                a {

                    background-color: var(--color-white);
                    background-color: transparent;
                    color: color-mix(in srgb, var(--color-user-background) 100%, var(--color-foreground) 95%);
                    box-shadow: 0 0 0em 1px color-mix(in srgb, var(--color-user-background) 0%, transparent 100%);
                    text-transform: uppercase;

                    abbr {
                        background-color: color-mix(in srgb, var(--color-user-background) 80%, var(--color-foreground) 80%);
                        border-radius: calc(var(--border-radius) / 1);
                        margin: 0 calc(var(--sapce) * 2) 0 var(--sapce);
                    }

                    &:hover {
                        border-radius: calc(var(--border-radius) / 4) !important;
                        color: var(--color-foreground);
                        background-color: color-mix(in srgb, var(--color-primary) 25%, var(--color-background) 100%);
                        // box-shadow: 0 0 0em 1px color-mix(in srgb, var(--color-user-background) 100%, transparent 0%);
                    }

                    &.--active {
                        background-color: color-mix(in srgb, var(--color-foreground) 5%, var(--color-background) 100%);
                        pointer-events: none;
                        border-radius: calc(var(--border-radius) / 4) !important;
                        box-shadow: none;
                    }
                }
            }
        }
    }
}